<template>
  <div class="col-12 col-md-12">
    <div class="card">
      <div class="text-title mb-2 mt-2 ml-3">
        <h5> Fixtures </h5>
      </div>

      <Loader v-if="fixtures.length <= 0"/>

      <div class="table-responsive" v-else>
        <table class="table table-striped">
          <tbody>
          <tr>
            <th>Title</th>
            <th>Teams</th>
            <th>Game Format</th>
            <th>Date</th>
            <th>Playing Hours</th>
            <th>Ground</th>
            <th>Venue</th>
            <th class="text-center">Action</th>
          </tr>
          <tr v-for="fixture in fixtures" :key="fixture.id">

            <td class="align-middle">
              {{ fixture.title }}
            </td>
            <td>
              {{ fixture.home_team_name }} vs {{ fixture.away_team_name }}
            </td>
            <td>
              {{ fixture.game_format }}
            </td>
            <td>{{ fixture.date }}</td>
            <td>{{ fixture.playing_hours }}</td>
            <td>{{ fixture.ground }}</td>
            <td>{{ fixture.venue }}</td>
            <td class="text-center">


              <div class="btn-group mb-2">
                <button class="btn btn-info btn-sm dropdown-toggle" type="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                  <i class="fa fa-bars"></i>
                </button>
                <div class="dropdown-menu" style="will-change: transform;">

                  <a class="dropdown-item" href="#"
                     data-toggle="modal"
                     data-target=".bd-example-modal-lg"
                     @click.prevent="loadScorers(fixture.id)">
                    Scorers
                  </a>

                  <a class="dropdown-item" href="#" @click.prevent="edit(fixture.id)">
                    Edit
                  </a>

                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item text-danger" href="#"
                     @click.prevent="destroy(fixture.id, fixture.tournament_id)">Delete</a>
                </div>
              </div>
            </td>
          </tr>

          </tbody>
        </table>
      </div>


    </div>
    <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="myLargeModalLabel">Add Scorers to Fixture</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 col-md-6" style="height: 320px; overflow-y: auto">
                <ul class="row list-unstyled list-unstyled-border mb-0">

                  <li class="media col-12 " v-for="(user, index) in users" :key="index">

                    <img alt="image" :src="$store.state.Global.rootCDN + user.photo"
                         class="user-img-radious-style user-list-img"
                         width="40">
                    <div class="media-body ml-2">
                      <div class="media-title" style="display: inline-block">
                        {{ user.name }}

                      </div>
                      <button class="btn btn-primary btn-vertical float-right mr-1"
                              @click.prevent="fixtureUsers.push(user); users.splice(index,1)">
                        <i class="fas fa-angle-double-right"></i>
                      </button>
                    </div>
                  </li>

                </ul>
              </div>
              <div class="col-12 col-md-6" style="height: 320px; overflow-y: auto">
                <ul class="row list-unstyled list-unstyled-border mb-0">

                  <li class="media col-12 " v-for="(user, index) in fixtureUsers" :key="index">

                    <button class="btn btn-danger btn-vertical float-left mr-2"
                            @click.prevent="users.push(user); fixtureUsers.splice(index,1);">
                      <i class="fas fa-angle-double-left"></i>
                    </button>

                    <img alt="image" :src="$store.state.Global.rootCDN + user.photo"
                         class="user-img-radious-style user-list-img"
                         width="40">
                    <div class="media-body ml-2">


                      <div class="media-title" style="display: inline-block">
                        {{ user.name }}

                      </div>


                    </div>
                  </li>

                </ul>
                <button class="btn btn-primary btn-sm btn-block"
                        style="position: relative; bottom: 0;" v-if="fixtureUsers.length > 0"
                        @click.prevent="storeFixtureUser">Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>
<script>
import Loader from "@/components/layouts/parts/Loader";
import {mapGetters, mapMutations} from 'vuex';
import {destroy, edit, loadScorers, storeFixtureUser} from "@/services/createFixture/Fixture";
import {useToast} from 'vue-toastification';

export default {
  data() {
    return {
      toast: useToast(),
      users: [],
      fixtureUsers: [],
      fixture_id: '',
    }
  },
  components: {
    Loader,
  },
  methods: {
    ...mapMutations({
      setFixtures: 'Fixture/setFixtures',
      setFixture: 'Fixture/setFixture',
    }),
    ...mapGetters({
      getFixtures: 'Fixture/getFixtures',
      getFixture: 'Fixture/getFixture',
    }),

    loadScorers(fixture_id) {

      this.fixtureUsers = [];
      this.users = [];
      this.fixture_id = fixture_id;

      loadScorers(fixture_id).then(response => {
        this.users = response.data.users;
        this.fixtureUsers = response.data.fixture_users;
      });

    },

    destroy(id, tournament_id) {

      this.$swal({

        title: 'Are you sure?',

        text: "You won't be able to revert this!",

        type: 'warning',

        showCancelButton: true,

        confirmButtonColor: '#d33',

        cancelButtonColor: '#3085d6',

        confirmButtonText: 'Yes, delete it!'

      }).then((result) => {

        if (result.value) {

          this.toast.clear();

          this.toast.info('processing...');

          destroy(id, tournament_id).then(response => {

            this.toast.clear();
            this.toast.info('Fixture deleted successfully!');
            this.fixtures = response.data;

          }, error => {
            this.toast.clear();
            this.toast.error(error.response.data.message)
          });


        }

      });


    },

    edit(id) {

      this.toast.clear();
      this.toast.info('processing...')
      edit(id).then(response => {
        this.fixture = response.data;
        this.toast.clear();
      });

    },


    storeFixtureUser() {

      this.toast.clear();
      this.toast.info('processing...');

      let data = {
        fixture_id: this.fixture_id,
        user: this.fixtureUsers,

      };

      storeFixtureUser(data).then(() => {
        this.toast.clear();
        this.toast.success('Scorers assigned to fixture successfully');
      });

    }
  },

  computed: {
    fixtures: {

      get() {
        return this.getFixtures();
      },

      set(value) {
        return this.setFixtures(value);
      }

    },
    fixture: {

      get() {
        return this.getFixture();
      },

      set(value) {
        return this.setFixture(value);
      }

    },
  },

}

</script>
<style scoped>
.btn.btn-vertical {
  padding: .8rem .2rem;

}
</style>