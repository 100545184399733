<template>
  <div class="col-12 col-md-6">
    <div class="card h-300">
      <div class="text-title mb-2 mt-2 ml-3">
        <h5> New Fixture </h5>
      </div>

      <Loader v-if="teams.length <= 0 "/>
      <div class="row mr-0 ml-0" v-else>
        <div class="col-12 col-md-6 h-370">
          <ul class="list-unstyled list-unstyled-border mb-0">
            <li class="media" v-for="(t,index) in teams" :key="t.id">
              <div class="media-body ml-3 mt-1">
                <div class="media-title">
                  {{ t.name }}
                  <i class="fa fa-times float-right font-10" style="color: red;" @click="teams.splice(index,1)"></i>
                </div>
                <div class="text-small text-muted pl-4">
                  <div class="custom-checkbox display-inline">

                    <input type="radio"
                           data-checkboxes="myteamgroup"
                           class="custom-control-input"
                           :id="'checkboxhteam-'+ t.id" name="home_team_id"
                           v-model="fixture.home_team_id"
                           :value="t.team_tournament_id">

                    <label :for="'checkboxhteam-'+ t.id" class="custom-control-label">Home Team</label>


                  </div>
                  <div class="custom-checkbox display-inline">

                    <input type="radio"
                           data-checkboxes="myteamgroup"
                           class="custom-control-input ml-4"
                           :id="'checkboxateam-'+ t.id" name="away_team_id"
                           v-model="fixture.away_team_id"
                           :value="t.team_tournament_id">

                    <label :for="'checkboxateam-'+ t.id" class="custom-control-label">Away Team</label>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-12">
              <div class="form-group ">
                <label>Title</label>
                <input type="text"
                       class="form-control form-control-sm" :class="{'is-invalid': errors.title}"
                       name="venue" v-model="fixture.title">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Game Format</label>
                <select type="text"
                        class="form-control form-control-sm " :class="{'is-invalid': errors.game_format_id}"
                        name="game_format_id" style="height: 32px; font-size: 8px;" v-model="fixture.game_format_id">
                  <option v-for="gm in gameFormats" :value="gm.id" :key="gm.id">{{ gm.name }}</option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group ">
                <label>Season</label>
                <select
                    class="form-control form-control-sm" :class="{'is-invalid': errors.season_id}"
                    name="season_id" v-model="fixture.season_id" style="height: 32px; font-size: 8px;">
                  <option v-for="s in seasons" :value="s.id" :key="s.id">{{ s.name }}</option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group ">
                <label>Date</label>
                <input type="date"
                       class="form-control form-control-sm" :class="{'is-invalid': errors.date}"
                       name="date" v-model="fixture.date">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group ">
                <label>Playing.H</label>
                <input type="text"
                       class="form-control form-control-sm" :class="{'is-invalid': errors.playing_hours}"
                       name="playing_hours" v-model="fixture.playing_hours">
              </div>

            </div>
            <div class="col-12">
              <div class="form-group ">
                <label>Ground</label>
                <input type="text"
                       class="form-control form-control-sm"
                       name="ground" v-model="fixture.ground">
              </div>
            </div>
            <div class="col-12">
              <div class="form-group ">
                <label>Venue</label>
                <input type="text"
                       class="form-control form-control-sm"
                       name="venue" v-model="fixture.venue">
              </div>
            </div>
            <div class="col-12 mb-4">

              <button class="btn btn-primary float-right ml-1" @click="save">Save</button>
              <button class="btn btn-danger float-right" @click="fixture = {}">Cancel</button>
            </div>

          </div>

        </div>
      </div>


    </div>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex';
import {loadTournaments, store} from "@/services/createFixture/Fixture";
import moment from 'moment';
import Loader from "@/components/layouts/parts/Loader";
import {useToast} from 'vue-toastification';

export default {
  data() {
    return {
      toast: useToast(),
      errors: {},
    };
  },
  components: {
    Loader,
  },
  methods: {

    ...mapMutations({
      setTeams: 'Fixture/setTeams',
      setTournaments: 'Fixture/setTournaments',
      setTournament: 'Fixture/setTournament',
      setGameFormats: 'Fixture/setGameFormats',
      setSeasons: 'Fixture/setSeasons',
      setFixture: 'Fixture/setFixture',
    }),
    ...mapGetters({
      getTeams: 'Fixture/getTeams',
      getTournaments: 'Fixture/getTournaments',
      getTournament: 'Fixture/getTournament',
      getGameFormats: 'Fixture/getGameFormats',
      getSeasons: 'Fixture/getSeasons',
      getFixture: 'Fixture/getFixture',
    }),
    moment(date) {
      return moment(date);
    },
    save() {
      this.errors = [];
      this.fixture.tournament_id = this.tournament.id;
      this.toast.clear();
      this.toast.info('processing...');
      store(this.fixture).then(() => {

        this.toast.clear();
        this.toast.success('Fixture Created Successfully!');
        this.fixture = {};
      }, error => {
        this.toast.clear();
        this.toast.error(error.response.data.message);
        this.errors = error.response.data.errors;
      });

    },
    loadTournaments() {
      this.tournament = {};
      this.tournaments = [];

      loadTournaments().then(response => {
        this.tournaments = response.data;
      });
    },

  },

  computed: {
    tournaments: {
      get() {
        return this.getTournaments();
      },
      set(value) {
        this.setTournaments(value);
      },
    },
    tournament: {
      get() {
        return this.getTournament();
      },
      set(value) {
        this.setTournament(value);
      },
    },

    teams: {
      get() {
        return this.getTeams();
      },
      set(value) {
        this.setTeams(value);
      },
    },
    gameFormats: {

      get() {
        return this.getGameFormats();
      },

      set(value) {
        return this.setGameFormats(value);
      }

    },
    seasons: {

      get() {
        return this.getSeasons();
      },

      set(value) {
        return this.setSeasons(value);
      }

    },
    fixture: {

      get() {
        return this.getFixture();
      },

      set(value) {
        return this.setFixture(value);
      }

    },


  },
  mounted() {
    this.loadTournaments();
  }
}
</script>

<style scoped>
.h-370 {
  max-height: 460px;
  overflow: auto;
}
</style>
