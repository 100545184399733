import API from '../API';

export function loadTournaments() {
    return API.get('create-fixture/load-tournaments');
}

export function loadTeams(tournament_id) {
    return API.get('create-fixture/load-teams?tournament_id=' + tournament_id);
}

export function store(data) {
    return API.post('create-fixture/store', data);
}

export function destroy(id, tournament_id) {
    return API.post('create-fixture/destroy', {id: id, tournament_id: tournament_id});
}

export function edit(id) {
    return API.get('create-fixture/edit?id=' + id);
}

export function loadScorers(id) {
    return API.get('create-fixture/load-scorers?id=' + id);
}

export function storeFixtureUser(data) {
    return API.post('create-fixture/fixture-user/store', data);
}