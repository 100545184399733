<template>
  <div class="col-12 col-md-6">
    <div class="card h-530">
      <div class="text-title mb-2 mt-2 ml-3">
        <h5> Tournaments </h5>
      </div>
      <Loader v-if="tournaments.length <= 0"/>
      <ul class="list-unstyled list-unstyled-border mb-0" v-else>
        <li class="media" v-for="t in tournaments" :key="t.id" @click="loadTeams(t)"
            :class="{'bg-secondary text-white': t.id === tournament.id}">
          <div class="media-body ml-3 mt-1">
            <div class="media-title" :class="{'text-white': t.id === tournament.id}">
              {{ t.name }}
            </div>
            <div class="text-small text-muted">
              {{ t.tournament_type }}
              |
              {{ moment(t.start_date).format('Y-MM-DD') }}
              |
              {{ moment(t.end_date).format('Y-MM-DD') }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex';
import {loadTeams, loadTournaments} from "@/services/createFixture/Fixture";
import moment from 'moment'
import Loader from "@/components/layouts/parts/Loader";

export default {
  components: {
    Loader,
  },
  methods: {
    ...mapMutations({
      setTournaments: 'Fixture/setTournaments',
      setTournament: 'Fixture/setTournament',
      setTeams: 'Fixture/setTeams',
      setGameFormats: 'Fixture/setGameFormats',
      setSeasons: 'Fixture/setSeasons',
      setFixtures: 'Fixture/setFixtures',
    }),
    ...mapGetters({
      getTournaments: 'Fixture/getTournaments',
      getTournament: 'Fixture/getTournament',
      getTeams: 'Fixture/getTeams',
      getGameFormats: 'Fixture/getGameFormats',
      getSeasons: 'Fixture/getSeasons',
      getFixtures: 'Fixture/getFixtures',
    }),
    moment(date) {


      return moment(date);


    },
    loadTournaments() {
      this.tournament = {};
      this.tournaments = [];
      this.teams = [];

      this.fixtures = [];

      loadTournaments().then(response => {
        this.tournaments = response.data;
      });
    },

    loadTeams(t) {
      this.tournament = t;
      this.teams = [];
      this.fixtures = [];
      loadTeams(t.id).then(response => {
        this.teams = response.data.teams;
        this.gameFormats = response.data.gameFormats;
        this.seasons = response.data.seasons;
        this.fixtures = response.data.fixtures;

      });
    }

  },

  computed: {
    tournaments: {
      get() {
        return this.getTournaments();
      },
      set(value) {
        this.setTournaments(value);
      },
    },
    tournament: {
      get() {
        return this.getTournament();
      },
      set(value) {
        this.setTournament(value);
      },
    },
    teams: {

      get() {
        return this.getTeams();
      },

      set(value) {
        return this.setTeams(value);
      }

    },
    gameFormats: {

      get() {
        return this.getGameFormats();
      },

      set(value) {
        return this.setGameFormats(value);
      }

    },
    seasons: {

      get() {
        return this.getSeasons();
      },

      set(value) {
        return this.setSeasons(value);
      }

    },
    fixtures: {

      get() {
        return this.getFixtures();
      },

      set(value) {
        return this.setFixtures(value);
      }

    },
  },
  mounted() {
    this.loadTournaments();
  }
}
</script>
<style scoped>
@media only screen and (min-width: 700px) {
  .h-530 {
    height: 530px !important;
    overflow-y: auto;

  }
}
</style>
