<template>
  <head-slot>
    <title>Scoring And Performance | Create Fixture</title>
  </head-slot>
  <div class="card card-primary">
    <div class="card-header">
      <h4>Fixture Management</h4>
    </div>
    <div class="card-body">

      <transition enter-active-class="animate__animated animate__fadeIn">

        <div class="row">

          <Tournament/>
          <Team/>
          <Fixture/>

        </div>

      </transition>

    </div>

  </div>
</template>
<script>
import Tournament from "@/components/scoringAndPerformance/createFixture/Tournament";
import Team from "@/components/scoringAndPerformance/createFixture/Team";
import Fixture from "@/components/scoringAndPerformance/createFixture/Fixture";

export default {
  components: {
    Tournament,
    Team,
    Fixture,
  }
}
</script>